import React from "react"
import { graphql } from "gatsby"
import type { HeadFC } from "gatsby"
import { SEO, Achievements } from "../components"
import { DefaultLayout } from "../layouts"
import { AchievementInterface } from "../typings/achievement.interface"

type AchievementsProps = {
    data: {
        achievements: {
            nodes: AchievementInterface[]
        }
    }
}

const achievementsPage = (props: AchievementsProps) => {
    const achievements = props.data.achievements.nodes
    return (
        <DefaultLayout>
            <div className="text-center my-10">
                <h1 className="font-inconsolata text-4xl leading-tight md:leading-normal">
                    Achievements
                </h1>
                <p className="mb-5 font-inconsolata">
                    Seluruh prestasi perlombaan yang kami raih selama bertahun-tahun dan menjadi kebanggaan tersendiri bagi kami dan Universitas Gunadarma.
                </p>
            </div>

            <Achievements data={achievements} />
        </DefaultLayout>
    )
}

export const achievementsPageQuery = graphql`
    query achievementsPageQuery {
        achievements: allAchievementsYaml {
            nodes {
                title
                rank
                year
                held_by
                logo {
                    publicURL
                }
            }
        }
    }
`

export default achievementsPage
export const Head: HeadFC = () => <SEO title="Achievements" />
